<template>
  <div >
   
    <b-card >
      <b-row>
      <b-col  cols="12"
        md="4"
        xl="3">
        <p >{{$t('SearchBy')}}</p>
        <vs-input
          name="Doctor Name"
         
          :placeholder="$t('DoctorName')"
          v-model="Search.DoctorName"
        />
      </b-col>
      <b-col  cols="12"
        md="1"
        xl="1">
      <span class="">  {{$t("Or")}}  </span>
    </b-col>
        <b-col  cols="12"
        md="4"
        xl="3">
       <p >{{$t('From')}}</p>

          <b-form-datepicker
        :placeholder="$t('DateFrom')"
        v-model="Search.DateFrom"
       
        name="DateFrom"
      ></b-form-datepicker>
        </b-col>
       
<b-col cols="12"
        md="4"
        xl="3">
  <p >{{$t('To')}}</p>

  <b-form-datepicker
        :placeholder="$t('DateTo')"
        v-model="Search.DateTo"
       
        name="DateTo"
      ></b-form-datepicker>
        </b-col>
       
      <div >
        <feather-icon icon="SearchIcon" @click.stop="SearchPrescription()" svgClasses=" w-16" class="mt-3 " />
      </div>
      </b-row>
     
    </b-card>

    <!-- <b-table responsive="sm" :items="FilteredList"/> -->

<h3  v-if="Model.length==0" >{{$t("NoDataToshow")}}</h3>
    <div >
      <b-row
       

        v-for="(item, index) in FilteredList"
        :key="index"
      >
        <b-col
        cols="12"
        md="2"
        xl="2"
        >
          {{ item.Note }}
        </b-col>
        <b-col
        cols="12"
        md="2"
        xl="2"
        >
          {{ item.Date | formatDate() }}
        </b-col>
        <b-col
        cols="12"
        md="2"
        xl="2"
        >
        </b-col>
        <b-col
        cols="12"
        md="2"
        xl="2"
        >
          <div>
            <vs-avatar size="large" :src="baseURL + item.DoctorImage" />
          </div>
          {{ item.DoctorName }}
        </b-col>
        <b-col
        cols="12"
        md="2"
        xl="2"

        >
          <vs-button
            @click="Download(item, index)"
            color="primary"
            class="text-white"
            text-color="rgb(69, 74, 98)"
            icon-pack="icon icon-download"
            icon="feather"
            >{{ $t("Download") }}
          </vs-button>
        </b-col>
        <vs-divider class="w-2/3" />
      </b-row>
    </div>

    <b-card class="">
      <b-row style="text-align:center">
        <b-col
        cols="12"
        md="4"
        xl="3"
        >
        <feather-icon icon="ChevronLeftIcon" size="16" class="mr-0 mr-sm-50"     :disabled="this.CurrentPage == 1"
            @click="GoToFirstPage()" /> 
           <feather-icon icon="ChevronsLeftIcon" size="16" class="mr-0 mr-sm-50"   :disabled="this.CurrentPage == 1"
            @click="GoBack()" /> 
        
        </b-col>
        <b-col
        cols="12"
        md="4"
        xl="3"
        >
          Page {{ CurrPage }} of {{ Math.ceil(Model.length / CountPerPage) }}
        </b-col>
        <b-col
        cols="12"
        md="4"
        xl="3"
        >
          
          <feather-icon icon="ChevronRightIcon" size="16" class="mr-0 mr-sm-50"   :disabled="
              this.CurrentPage == Math.ceil(Model.length / CountPerPage)
            "
            @click="GoNext()" /> 
           <feather-icon icon="ChevronsRightIcon" size="16" class="mr-0 mr-sm-50"  :disabled="
              this.CurrentPage == Math.ceil(Model.length / CountPerPage)
            "
            @click="GoToLastPage()" /> 
        </b-col>
      </b-row>
    </b-card>
    <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-8 flex flex-wrap items-center justify-end">
            <b-button type="border" color="danger" @click="back()">{{
              $t("Back")
            }}</b-button>
          </div>
        </div>
      </div>
  </div>
</template>
<script>
import { domain } from "@/gloabelConstant.js";
import modulePatient from "@/store/Patient/modulePatient.js";
import { BAlert, BButton, BCard, BCardBody, BCardText, BCol, BFormDatepicker, BLink, BRow, BTable, BTableLite } from 'bootstrap-vue';
export default {
  components: {
    BFormDatepicker,
    BTable, BAlert, BButton, BCard, BCardBody, BCardText, BCol, BLink, BRow, BTableLite
  },
  data() {
    return {
      EnglishLan:false,
      CurrentPage: 1,
      CountPerPage: 4,
      Search: {

        PatientID:0
      },
      baseURL: domain,
      Model: []
    };
  },
  computed: {
    FilteredList() {
      return this.Model.slice(
        (this.CurrentPage-1)*this.CountPerPage,
        (this.CurrentPage-1)*this.CountPerPage + this.CountPerPage
      );
    },
    CurrPage() {

      return this.CurrentPage;
    }
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    Download(item, index) {
      debugger
      // this.$store.commit("UPDATE_PrescriptionsList", item);
      console.log(index);
      this.$router.push({ name: "PrescriptionPDF" ,params:{ID:item.PatientReservationSessionID} });
    },
    GoToFirstPage() {
      this.CurrentPage = 0;
    },
    GoBack() {
      this.CurrentPage = this.CurrentPage - 1;
    },
    GoToLastPage() {
      this.CurrentPage = Math.ceil(this.Model.length / this.CountPerPage);
    },
    GoNext() {
      this.CurrentPage = this.CurrentPage + 1;
    },
    SearchPrescription(){
             this.Search.PatientID= this.$store.state.AppActiveUser.Patient.ID

    this.$store
      .dispatch("patientList/SearchReservationSessionPrescriptions",  this.Search)
      .then(res => {
        if (res.status == 200) {
          this.Model = res.data.Data;
            if(this.Model==null||this.Model.length==0){
             this.$vs.notify({
          title: this.$t("NoData"),
          text: this.$t("NoDataToshow"),
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "warning"
        });
        }
        }
      })
      .catch(() => {
        window.showError();
      });
    }
  },
  created() {
        this.EnglishLan=localStorage.getItem("SaveLang")=="en"||localStorage.getItem("SaveLang")==null?true:false;

    if (!modulePatient.isRegistered) {
      this.$store.registerModule("patientList", modulePatient);
      modulePatient.isRegistered = true;
    }
 this.SearchPrescription();
  }
};
</script>
<style>

</style>
